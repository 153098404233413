
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { Button, TextField, FormControl } from "@material-ui/core";
import { alertFailure, alertSuccess } from "../../store/actions/alert";
import DefaultLayout from "../../components/Layout/DefaultLayout";
import { Card, CardBody } from "reactstrap";
import FormEditor from "../../components/Base/FormEditor";
import { addDisclaimerList, getDisclaimerTermList, updateDisclaimerListById } from "../../request/disclaimer";

// Reusable TitleInput Component
const TitleInput = ({ control, errors }: any) => (
  <FormControl variant="outlined" margin="normal" fullWidth>
    <h4 className="card-title mb-3">Enter Title</h4>
    <Controller
      name="title"
      control={control}
      rules={{
        required: "Title is required",
        minLength: {
          value: 5,
          message: "Title must be at least 5 characters long",
        },
      }}
      render={(field ) => (
        <TextField
          {...field}
          id="title"
          variant="outlined"
          fullWidth
          error={!!errors.title}
          helperText={errors.title?.message}
        />
      )}
    />
  </FormControl>
);

// Main Component
const DisclaimerList = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    defaultValues: {
      title: "",
      content: "",
    },
  });

  const dispatch = useDispatch();
  const [termsAndServicesData, setTermsAndServicesData] = useState("");
  const [disclaimerList, setDisclaimerList] = useState<any[]>([]);
  const [editId, setEditId] = useState<number | null>(null);

  const fetchData = useCallback(async () => {
    try {
      const response = await getDisclaimerTermList();
      if (response?.status === 200) {
        const data = response?.data || [];
        setDisclaimerList(data);

        if (data[0]) {
          const selectedDisclaimer = data[0];
          console.log(selectedDisclaimer)
          setEditId(selectedDisclaimer.id);
          if (selectedDisclaimer.content) {
            const termsContent = JSON.parse(selectedDisclaimer.content);
            setTermsAndServicesData(termsContent.termsAndDisclaimer || termsContent);
            setValue("content", termsContent);
            setValue("title", selectedDisclaimer.title);
          }
        }
      } else {
        dispatch(alertFailure("Failed to fetch disclaimers"));
      }
    } catch (error) {
      dispatch(alertFailure("Error fetching disclaimers"));
    }
  }, [dispatch, setValue]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const onSubmit = async (data: any) => {
    try {
      const requestData = {
        title: data.title,
        content: termsAndServicesData,
      };

      let response;
      if (editId) {
        response = await updateDisclaimerListById(requestData, editId);
      } else {
        response = await addDisclaimerList(requestData);
      }

      if (response?.status === 200) {
        dispatch(alertSuccess(editId ? "Updated Successfully" : "Added Successfully"));
        fetchData();
        reset();
      } else {
        dispatch(alertFailure("Failed to save disclaimer"));
      }
    } catch (error: any) {
      dispatch(alertFailure("Error: " + error.message));
    }
  };

  return (
    <DefaultLayout>
      <div className="mt-4">
        <Card>
          <CardBody className="p-4">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TitleInput control={control} errors={errors} />

              <div className="mt-3">
                <h4 className="card-title mb-3">Terms & Disclaimer</h4>
                <Controller
                  name="content"
                  control={control}
                  rules={{
                    required: "Content is required",
                  }}
                  render={(field) => (
                    <FormEditor
                      data={termsAndServicesData}
                      setData={(value: any) => {
                        field.onChange(value);
                        setTermsAndServicesData(value);
                      }}
                    />
                  )}
                />
                {errors.content && (
                  <p style={{ color: "red", marginTop: "8px" }}>{errors.content.message}</p>
                )}
              </div>

              <Button
                variant="contained"
                color="primary"
                type="submit"
                className="mt-4"
              >
                Submit
              </Button>
            </form>
          </CardBody>
        </Card>
      </div>
    </DefaultLayout>
  );
};

export default DisclaimerList;


