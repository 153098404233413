import { BaseRequest } from "./Request";
import { apiRoute } from "../utils";
const queryString = require("query-string");


export const getUserTermsList = async (queryParams: any) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/user-terms`);
  url += "?" + queryString.stringify(queryParams);

  const response = (await baseRequest.get(url)) as any;
  const resObject = await response.json();

  return resObject;
};

export const getDisclaimerTermList = async () => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/terms-and-conditions`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const addDisclaimerList = async (termsList: {
  title: string;
  content: string;
}) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/terms-and-conditions`);
  const response = (await baseRequest.post(url, termsList)) as any;
  return await response?.json();
};

export const updateDisclaimerListById = async (
  termsList: {   title: string;
    content: string;},
  Id: number
) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/terms-and-conditions/${Id}`);
  const response = (await baseRequest.patch(url, termsList)) as any;
  return await response?.json();
};

export const getDisclaimerListById = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/terms-and-conditions/${Id}`);
  const response = (await baseRequest.get(url)) as any;
  return await response.json();
};

export const deleteDisclaimerList = async (Id: number) => {
  const baseRequest = new BaseRequest();
  let url = apiRoute(`/terms-and-conditions/${Id}`);
  const response = (await baseRequest.delete(url, {})) as any;
  return await response?.json();
};




